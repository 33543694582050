import React from "react";
import { Link } from "gatsby";
import Page from "./../components/Page";
import Section from "./../components/Section";
import SEO from "./../components/SEO";
import WithSideNav from "../components/WithSideNav";

function ReleaseNotes() {
  return (
    <Page>
      <Section>
        <WithSideNav sideNavType="community" title="Release Notes">
          <SEO title="Release Notes | OHIF" />
          <p className="mt-8">
            At OHIF, we strive for consistent and dependable releases to keep
            your medical imaging workflows cutting-edge and reliable. Within
            this page, you'll discover a comprehensive list of our latest and
            past releases, complete with detailed release notes.
          </p>

          <ul className="mt-8 text-primary-light">
            <Link to="/release-notes/3p9">
              <li>Latest (v3.9.0)</li>
            </Link>
            <Link to="/release-notes/3p8">
              <li>v3.8.0</li>
            </Link>
            <Link to="/release-notes/3p7">
              <li>v3.7.0</li>
            </Link>
            <Link to="/release-notes/previous">
              <li>Previous releases</li>
            </Link>
          </ul>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
